let LUIS = {
    name: 'Luis',
    desc: 'smart and reliable',
    car: 'merc',
    p_color: '#8Eb69B',
    s_color: '#49FF00'
};

let SUPERMAX = {
    name: 'Supermax',
    desc: 'fast and aggressive',
    car: 'rb',
    p_color: 'rgb(0, 56, 123)',
    s_color: 'red'
};

module.exports = {
    LUIS,
    SUPERMAX
}