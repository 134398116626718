let TRACKS = [
    {
        id: 'speed-garage',
        name: 'Speed Garage',
        laps: 70,
        factor: 1,
        SOFT: {
            high: 270,
            deg: -0.17 / 2,
            name: 'soft',
            color: 'red',
            code: 's'
        },
        MED: {
            high: 268,
            deg: -0.093 / 2,
            name: 'medium',
            color: 'yellow',
            code: 'm'
        },
        HARD: {
            high: 266,
            deg: -0.08 / 2,
            name: 'hard',
            color: 'white',
            code: 'h'
        },
        getOppoStrategy: function () {
            return {
                0: this.MED,
                40: this.HARD
            }
        },
        c1: '#34495E',
        c2: '#AED6F1',
        music: new Audio(process.env.PUBLIC_URL + '/sounds/tame_time.mp3')
    },
    {
        laps: 66,
        id: 'anxious-metropolis',
        name: 'Anxious Metropolis',
        factor: 0.82,
        SOFT: {
            high: 270,
            deg: -0.25 / 2,
            name: 'soft',
            color: 'red',
            code: 's'
        },
        MED: {
            high: 268,
            deg: -0.18 / 2,
            name: 'medium',
            color: 'yellow',
            code: 'm'
        },
        HARD: {
            high: 266,
            deg: -0.15 / 2,
            name: 'hard',
            color: 'white',
            code: 'h'
        },
        c1: '#868B8E',
        c2: '#B9B7BD',
        music: new Audio(process.env.PUBLIC_URL + '/sounds/anxious_metropolis.mp3'),

        getOppoStrategy: function () {
            return {
                0: this.MED,
                38: this.SOFT
            }
        }
    },
    {
        laps: 69,
        id: 'nice-road',
        name: 'Nice Road',
        factor: 0.83,
        SOFT: {
            high: 280,
            deg: -0.22 / 2,
            name: 'soft',
            color: 'red',
            code: 's'
        },
        MED: {
            high: 278.5,
            deg: -0.19 / 2,
            name: 'medium',
            color: 'yellow',
            code: 'm'
        },
        HARD: {
            high: 276.5,
            deg: -0.127 / 2,
            name: 'hard',
            color: 'white',
            code: 'h'
        },
        c1: '#1D741B',
        c2: '#D2FBA4',
        music: new Audio(process.env.PUBLIC_URL + '/sounds/thub.mp3'),

        getOppoStrategy: function () {
            return {
                0: this.MED,
                25: this.SOFT,
                45: this.SOFT
            }
        }
    }
];


module.exports = {
    TRACKS
}