import Race from './Race'
import './App.css';
import { useEffect, useState } from 'react';
import { LUIS, SUPERMAX } from './Drivers'
import { isMobile } from 'react-device-detect';
import { TRACKS } from './Tracks'

let trackId = new URLSearchParams(window.location.search).get('t'), selectedTrack = TRACKS.find((track) => {
  return track.id === trackId;
}), CHALLENGE_OPPO_STRATEGY;

function b64_to_utf8(str) {
  return decodeURIComponent(escape(window.atob(str)));
}

if (selectedTrack) {
  let strategy_b64 = new URLSearchParams(window.location.search).get('os');

  // Parse any strategy passed as query param
  if (strategy_b64) {
    try {
      CHALLENGE_OPPO_STRATEGY = JSON.parse(b64_to_utf8(strategy_b64));
      let codeToTyreObj = {
        's': selectedTrack.SOFT,
        'm': selectedTrack.MED,
        'h': selectedTrack.HARD
      };

      for (const lap in CHALLENGE_OPPO_STRATEGY) {
        CHALLENGE_OPPO_STRATEGY[lap] = codeToTyreObj[CHALLENGE_OPPO_STRATEGY[lap]];
        if (!CHALLENGE_OPPO_STRATEGY[lap]) throw Error();
      }

      if (!CHALLENGE_OPPO_STRATEGY[0]) throw Error();
    } catch (e) {
      CHALLENGE_OPPO_STRATEGY = null;
    }
  }
}

function App() {
  const [currTime, setTime] = useState(0);
  const [driver, setDriver] = useState(null);
  const [track, setTrack] = useState(selectedTrack);

  let completedTracks = TRACKS.filter((track) => {
    return localStorage.getItem(track.id);
  }).length;

  let totalTracks = TRACKS.length;

  useEffect(() => {
    const interval = setInterval(() => {
      setTime(Math.floor(new Date().getTime() / 1000));
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  // if (isMobile) {
  //   return <div className="driverSelect">
  //     <h2 className="driver" style={{ color: 'black', marginBottom: '20px', cursor: 'default' }}>You can't play on mobile, yet :/</h2>
  //   </div>
  // }

  return (
    <div>
      {(!driver || !track) &&

      <div id="title">Pitlane: Luis v/s Supermax</div>}
      {!driver &&
        <div className="homeContainer">
          <div className="driverSelect">
            <h2 style={{ color: 'black', marginBottom: '20px', cursor: 'default' }}>CHOOSE YOUR DRIVER</h2>
            <h1 className="driver" style={{ color: LUIS.p_color }} onClick={() => { setDriver(LUIS) }}>LUIS - smart & reliable</h1>
            <h1 className="versus">v / s</h1>
            <h1 className="driver" style={{ color: SUPERMAX.p_color }} onClick={() => { setDriver(SUPERMAX) }}>SUPERMAX - fast & aggressive</h1>
          </div>
          <div className="rules">
            <h2 style={{ textAlign: 'center' }}>RULES</h2>
            <div className="rule">1. First driver to complete the required number of laps wins</div>
            <div className="rule">2. There are 3 different tyre types available with different speeds and durability. During the course of a race, a driver has to use at least two different types of tyres</div>
            <div className="rule">3. Driver can make as many pitstops as they'd like, but remember, pitstops cost you time</div>
          </div>
        </div>}
      {driver && !track &&
        <div className="homeContainer">
          <div className="driverSelect">
            <h2 style={{ textAlign: 'center', color: 'black', cursor: 'default', fontWeight: 'bold', paddingBottom: '0px', marginBottom: '0px' }}>PICK A CIRCUIT</h2>
            <div style={{ textAlign: 'center', color: 'black', marginTop: '10px', marginBottom: '30px', cursor: 'default' }}>Circuits Completed: {completedTracks}&nbsp;|&nbsp;Circuits Remaining: {totalTracks - completedTracks}</div>
            {TRACKS.map(track => {
              return <div className="track" style={{ padding: '10px', color: localStorage.getItem(track.id) ? '#18A558' : 'red' }} onClick={() => { setTrack(track) }}>{track.name}</div>
            })}
            <div style={{ fontSize: '20px', textAlign: 'center', position: 'absolute', top: '20px', right: '20px', cursor: 'pointer' }} onClick={() => { window.location.reload(); }}>Home</div>
            {/* <div onClick={() => {setReadRules(true);}} style={{color: 'red', textAlign: 'center'}}>OK</div> */}
          </div>
        </div>}
      {driver && track && <Race props={{ currTime: currTime, driver: driver, track: track, setTrack: setTrack, setDriver, cop: CHALLENGE_OPPO_STRATEGY, isMobile }} />}
    </div>
  );
}

export default App;
