import React, { useState, useRef, useEffect } from 'react';
import Car from './Car';
import './Race.css';
import box_box_mp3 from './boxbox.mp3'
import { LUIS, SUPERMAX } from './Drivers'
import { Mixpanel } from './Mixpanel';
import { isMobile } from 'react-device-detect';


function strategy_pretty_print(strategy) {
    let prettyStrategy = {};
    for (const lap in strategy) {
        prettyStrategy[lap] = strategy[lap].name;
    }
    return prettyStrategy;
}
function utf8_to_b64(str) {
    return window.btoa(unescape(encodeURIComponent(str)));
}

let SOFT, MED, HARD, DEFAULT_OPPO_STRATEGY;

let boxBoxAudio = new Audio(box_box_mp3);
function boxbox() {
    boxBoxAudio.play();
}

let prev_p_pos, prev_o_pos, STRATEGY = {}, PLAYER_WIN = false;

function Race({ props }) {
    SOFT = props.track.SOFT;
    MED = props.track.MED;
    HARD = props.track.HARD;

    props.track.music.volume = 0.5;

    DEFAULT_OPPO_STRATEGY = props.track.getOppoStrategy();

    const laps = useRef(props.track.laps),
        km_per_lap = useRef(5),
        time_in_quanta_in_hr = useRef(3 / 200);

    !STRATEGY[0] && (STRATEGY[0] = SOFT);
    const [playerCar, setPlayerCar] = useState({
        tyre: SOFT,
        speed: SOFT.high,
        position: 0,
        inPit: false,
        strategy: {
            0: SOFT,
            35: HARD
        },
        lastPit: 0,
        driver: props.driver
    });

    let oppoStrategy = props.cop || DEFAULT_OPPO_STRATEGY;
    const [oppoCar, setOppoCar] = useState({
        tyre: oppoStrategy[0],
        speed: oppoStrategy[0].high,
        position: 0,
        inPit: false,
        strategy: oppoStrategy,
        lastPit: 0,
        driver: (props.driver.name === 'Luis' && SUPERMAX) || LUIS
    });

    const [raceStarted, setRaceStarted] = useState(false);
    const [pitPlayer, setPitPlayer] = useState(false);

    const [displayState, setDisplayState] = useState({
        name: null,
        details: null,
        expiry: null
    });

    const [displayCopied, setDisplayCopied] = useState(false);
    const [credits, setCredits] = useState(false);


    function start() {
        props.track.music.play();
        setRaceStarted(true);
    }
    function reset() {

        props.track.music.pause();
        props.track.music.currentTime = 0;

        setPlayerCar({
            ...playerCar,
            tyre: SOFT,
            speed: SOFT.high,
            position: 0,
            inPit: false,
            strategy: {
                0: SOFT,
                35: MED
            },
            lastPit: 0
        });

        if (PLAYER_WIN) {
            setOppoCar({
                ...oppoCar,
                tyre: STRATEGY[0],
                speed: STRATEGY[0].high,
                position: 0,
                inPit: false,
                strategy: STRATEGY,
                lastPit: 0,
            });
        } else {
            setOppoCar({
                ...oppoCar,
                tyre: oppoCar.strategy[0],
                speed: oppoCar.strategy[0].high,
                position: 0,
                inPit: false,
                lastPit: 0,
            });
        }


        setRaceStarted(false);
        setPitPlayer(false);

        setDisplayState({
            name: null,
            details: null,
            expiry: null
        });

        setDisplayCopied(false);

        STRATEGY = {};
        STRATEGY[0] = SOFT;
        PLAYER_WIN = false;
    }

    function tick() {
        if (!raceStarted) {
            return;
        }

        if (playerCar.position > km_per_lap.current * laps.current || oppoCar.position > km_per_lap.current * laps.current) {
            let message, sub_message;

            if (playerCar.position > oppoCar.position) {
                const used_tyres = Object.values(STRATEGY).map(tyre => tyre.name);
                const unique_tyres = [...new Set(used_tyres)];

                if (unique_tyres.length < 2) {
                    message = oppoCar.driver.name + ' wins!';
                    sub_message = playerCar.driver.name + ' disqualified for using only one tyre type in the race'
                    PLAYER_WIN = false;
                } else {
                    message = 'Congratulations, ' + playerCar.driver.name + ' wins!';
                    PLAYER_WIN = true;
                }
            } else {
                message = oppoCar.driver.name + ' wins!';
                PLAYER_WIN = false;
            }

            if (PLAYER_WIN && !props.cop) {
                localStorage.setItem(props.track.id, 1);
            }
            setDisplayState({
                name: 'finished',
                message: message,
                sub_message: sub_message,
                expiry: props.currTime + 10000000
            });

            setRaceStarted(false);
            Mixpanel.track(
                'RACE_COMPLETED',
                {
                    'category': 'Race',
                    'action': 'RACE_COMPLETED',
                    'track': props.track.id,
                    'player_win': PLAYER_WIN,
                    'player_driver': props.driver.name,
                    'strategy': strategy_pretty_print(STRATEGY),
                    'mode': (props.cop && 'challenge') || 'default',
                    'opposition_strategy': strategy_pretty_print(oppoStrategy)
                });
            return;
        }

        prev_o_pos = oppoCar.position;
        prev_p_pos = playerCar.position;

        let currPlayerLap = 1 + Math.floor(playerCar.position / km_per_lap.current),
            nextPlayerPosition = playerCar.position + time_in_quanta_in_hr.current * playerCar.speed,
            nextPlayerLap = 1 + Math.floor(nextPlayerPosition / km_per_lap.current),
            playerPitting = false,
            factor = 1;

        if (nextPlayerLap > currPlayerLap && pitPlayer && playerCar.lastPit !== nextPlayerLap) {
            playerPitting = true;
            factor = props.track.factor;

            setDisplayState({
                name: 'pitting',
                details: {
                    driver: playerCar.driver.name,
                    tyre: playerCar.nextTyre.name,
                    tyreColor: playerCar.nextTyre.color
                },
                expiry: props.currTime + 3
            });

            setPlayerCar({
                ...playerCar,
                lastPit: nextPlayerLap,
                tyre: playerCar.nextTyre,
                speed: playerCar.nextTyre.high,
                inPit: true
            });

            STRATEGY[nextPlayerLap] = playerCar.nextTyre;
            setPitPlayer(false);
        }

        let currOppoLap = 1 + Math.floor(oppoCar.position / km_per_lap.current),
            nextOppoPosition = oppoCar.position + time_in_quanta_in_hr.current * oppoCar.speed,
            nextOppoLap = 1 + Math.floor(nextOppoPosition / km_per_lap.current),
            oppoPitting = false;


        if (nextOppoLap > currOppoLap && Object.keys(oppoCar.strategy).includes(String(nextOppoLap)) && oppoCar.lastPit !== nextOppoLap) {
            oppoPitting = true;
            factor = props.track.factor;

            setOppoCar({
                ...oppoCar,
                lastPit: nextOppoLap,
                tyre: oppoCar.strategy[nextOppoLap],
                speed: oppoCar.strategy[nextOppoLap].high,
                inPit: true
            });

            setDisplayState({
                name: 'pitting',
                details: {
                    tyre: oppoCar.strategy[nextOppoLap].name,
                    driver: oppoCar.driver.name,
                    tyreColor: oppoCar.strategy[nextOppoLap].color
                },
                expiry: props.currTime + 3
            });
        }

        if (!playerPitting) {
            setPlayerCar({
                ...playerCar, position: playerCar.position + time_in_quanta_in_hr.current * playerCar.speed * factor,// * (0.9 + Math.random() / 10),
                speed: playerCar.speed + playerCar.tyre.deg * (time_in_quanta_in_hr.current * playerCar.speed * factor),// * (0.9 + Math.random() / 10),
                inPit: false
            });
        }


        if (!oppoPitting) {
            setOppoCar({
                ...oppoCar, position: oppoCar.position + time_in_quanta_in_hr.current * oppoCar.speed * factor,// * (0.9 + Math.random() / 10),
                speed: oppoCar.speed + oppoCar.tyre.deg * (time_in_quanta_in_hr.current * oppoCar.speed * factor),// * (0.9 + Math.random() / 10),
                inPit: false
            });
        }
    }

    function changeTyrePlayer(type) {
        if (playerCar.inPit) {
            return;
        }
        if (!raceStarted) {
            setPlayerCar({
                ...playerCar,
                tyre: type,
                speed: type.high
            });

            STRATEGY[0] = type;
        } else {
            boxbox();
            setPlayerCar({ ...playerCar, nextTyre: type });
            setPitPlayer(true);
        }
    }

    useEffect(() => {
        if (props.currTime > displayState.expiry) {
            tick();
        }
    }, [props.currTime]);


    let p_pos = playerCar.position;
    let o_pos = oppoCar.position;

    if (displayState.name === 'pitting' && props.currTime < displayState.expiry) {
        p_pos = prev_p_pos;
        o_pos = prev_o_pos;
    }

    return (
        <div className="Race" style={{ backgroundColor: 'blue' }}>
            {credits && <div id="overlay">
                <div class="credits">
                <div>game developed by <a href="https://www.radx.in/" target="_blank" rel="noopener noreferrer">sri_rad</a></div>
                <div>car css by <a href="https://codepen.io/kol123/pen/duJrA" target="_blank" rel="noopener noreferrer">sameer kolhar on codepen</a></div>
                <div>pictures of tyres from  <a href="https://www.pirelli.com/tyres/en-ww/motorsport/f1/tyres" target="_blank" rel="noopener noreferrer">pirelli</a></div>
                <div>music from <a href="https://soundcloud.com/sri_rad" target="_blank" rel="noopener noreferrer">sri_rad's soundcloud</a></div>
                <div>feedback from karan, raghu, satish, shivam</div>
                <div style={{textDecoration: 'underline', color: 'red', cursor: 'pointer'}} onClick={() => {
                    setCredits(false);
                }}>close</div>
                </div>
                </div>

        }
            {displayState.name === 'pitting' && props.currTime < displayState.expiry &&
                <div id="overlay"><h1 className="info">{displayState.details.driver}&nbsp;is pitting for&nbsp;<span style={{ color: displayState.details.tyreColor }}>{displayState.details.tyre}</span>&nbsp;tyres!</h1></div>}
            {displayState.name === 'finished' && props.currTime < displayState.expiry && <div id="overlay">
                <div className="info">
                    <h1>{displayState.message}</h1>
                    {PLAYER_WIN &&
                        <div>
                            <h1 style={{ color: 'yellow', cursor: 'pointer', fontSize: '20px' }} onClick={() => {
                                let winningStrategy = {};
                                for (const lap in STRATEGY) {
                                    winningStrategy[lap] = STRATEGY[lap].code;
                                }

                                navigator.clipboard.writeText('Can you beat my tyre strategy to win a race?\n' + window.location.origin + '?t=' + props.track.id + '&os=' + utf8_to_b64(JSON.stringify(winningStrategy)));
                                setDisplayCopied(true);

                            }}>Challenge a friend to beat your winning strategy</h1>
                            <h1 style={{ color: 'yellow', cursor: 'pointer', fontSize: '20px' }} onClick={() => {
                                reset();
                            }}>Race against your winning strategy</h1>
                            <h1 style={{ color: 'yellow', cursor: 'pointer', fontSize: '20px' }} onClick={() => {
                                reset();

                                if (props.cop) {
                                    window.location.href = window.location.origin;
                                } else {
                                    props.setTrack(null);
                                }
                            }}>Race another circuit</h1>
                            {displayCopied && <h3 id="copiedMessage">Link copied to clipboard</h3>}
                        </div>
                    }
                    {!PLAYER_WIN &&
                        <div>
                            <h3 style={{ cursor: 'pointer', fontSize: '15px' }}>{displayState.sub_message}</h3>
                            <h1 style={{ color: 'yellow', cursor: 'pointer', fontSize: '25px' }} onClick={() => {
                                reset();
                            }}>Try Again</h1>
                        </div>
                    }
                </div>
            </div>}
            <h1 className="laps" style={{ position: 'absolute', padding: '10px', color: 'red', left: !isMobile? 0: '', right: isMobile? 0: '' }}>
                LAP: {Math.min(1 + Math.floor(playerCar.position / km_per_lap.current), laps.current)}/{laps.current}
            </h1>
            {isMobile? 
            (<div style={{backgroundColor: props.track.c1, height: '600px'}}>
                <div style={{width: '60%', display: 'inline-block'}}>
                    {!playerCar.inPit && <Car props={{isMobile: props.isMobile, pos: ((p_pos > o_pos) && (40 + (p_pos - o_pos) * 10)) || 0, driver: playerCar.driver, tyre: playerCar.tyre, player: true }} />}
                    {!oppoCar.inPit && <Car props={{isMobile: props.isMobile, pos: (!(p_pos > o_pos) && (40 + (o_pos - p_pos) * 10)) || 0, driver: oppoCar.driver, tyre: oppoCar.tyre }} />}
                </div>
                <div className="bottomBoxItem" style={{ width: '40%', display: 'inline-block', position: 'relative', '-webkit-transform':'scale(0.6)' }}>
                    {!raceStarted && <div style={{ paddingTop: '10px' }}>Choose a tyre to start the race with</div>}
                    {raceStarted && <div style={{ paddingTop: '10px' }}>Choose a tyre to pit and change</div>}
                    <div>
                        <div className="imgContainer"><img className="imgResp" onClick={() => changeTyrePlayer(SOFT)} src="https://i.imgur.com/8XkoPg5.png" alt=""></img><div className="imgDesc">soft - starts fast, degrades fast too</div></div>
                        <div className="imgContainer"><img className="imgResp" onClick={() => changeTyrePlayer(MED)} src="https://i.imgur.com/TzEsPbx.png" alt=""></img><div className="imgDesc">medium - balanced</div></div>
                        <div className="imgContainer"><img className="imgResp" onClick={() => changeTyrePlayer(HARD)} src="https://i.imgur.com/E053SSC.png" alt=""></img><div className="imgDesc">hard - starts slower, degrades slowly</div></div>
                    </div>
                    {!raceStarted && <h1 className="start" onClick={() => { start(); }}>START</h1>}
                    {raceStarted && <h1 className="forfeit" onClick={() => {
                        reset();
                    }}>FORFEIT</h1>}
                </div>
            </div> ): 
            (<>
                <div style={{ backgroundColor: props.track.c1, height: '500px', display: 'table-cell', width: '1000vw', verticalAlign: 'middle' }}>
                {!playerCar.inPit && <Car props={{ pos: ((p_pos > o_pos) && (35 + (p_pos - o_pos) * 5)) || 0, driver: playerCar.driver, tyre: playerCar.tyre, player: true }} />}
                {!oppoCar.inPit && <Car props={{ pos: (!(p_pos > o_pos) && (35 + (o_pos - p_pos) * 5)) || 0, driver: oppoCar.driver, tyre: oppoCar.tyre }} />}
                {/* <div style={{ position: 'absolute', bottom: 0 }}>HHH</div> */}
                </div>
            </>
            )}
            <div style={{ backgroundColor: props.track.c2, height: '500px', width: '100%', verticalAlign: 'middle', textAlign: 'center' }}>
                <div className="bottomBoxItem" style={{ width: props.isMobile?'45%':'33%', textAlign: 'left', verticalAlign: 'top', paddingTop: '30px' }}>
                    <div className="infoText">Track: {props.track.name}</div>
                    <div className="infoText">Tyres Used: {Object.keys(STRATEGY).map((lap) => {
                        return STRATEGY[lap].name
                    }).join(', ')}</div>
                    <div className="infoText">Current Tyre Age: {Math.max(Math.min(1 + Math.floor(playerCar.position / km_per_lap.current), laps.current) - Math.max(...Object.keys(STRATEGY)), 0)} laps</div>
                    <div className="infoText">Mode: {(props.cop && 'Challenge') || 'Default'}</div>
                </div>
                {!props.isMobile && <div className="bottomBoxItem" style={{ width: '33%' }}>
                    {!raceStarted && <div style={{ paddingTop: '10px' }}>Choose a tyre to start the race with</div>}
                    {raceStarted && <div style={{ paddingTop: '10px' }}>Choose a tyre to pit and change</div>}
                    <div>
                        <div className="imgContainer"><img className="imgResp" onClick={() => changeTyrePlayer(SOFT)} src="https://i.imgur.com/8XkoPg5.png" alt=""></img><div className="imgDesc">soft - starts fast, degrades fast too</div></div>
                        <div className="imgContainer"><img className="imgResp" onClick={() => changeTyrePlayer(MED)} src="https://i.imgur.com/TzEsPbx.png" alt=""></img><div className="imgDesc">medium - balanced</div></div>
                        <div className="imgContainer"><img className="imgResp" onClick={() => changeTyrePlayer(HARD)} src="https://i.imgur.com/E053SSC.png" alt=""></img><div className="imgDesc">hard - starts slower, degrades slowly</div></div>
                    </div>
                    {!raceStarted && <h1 className="start" onClick={() => { start(); }}>START</h1>}
                    {raceStarted && <h1 className="forfeit" onClick={() => {
                        reset();
                    }}>FORFEIT</h1>}
                </div>}
                <div className="bottomBoxItem rightBox" style={{ width: props.isMobile?'45%':'33%' }}>
                    <div className="rightBoxItem" onClick={() => {
                        window.location.href = window.location.origin;
                    }}>HOME</div>
                    <div className="rightBoxItem" onClick={() => {
                        reset();

                        if (props.cop) {
                            window.location.href = window.location.origin;
                        } else {
                            props.setTrack(null);
                        }
                    }}>RACE ANOTHER CIRCUIT</div>
                    <div className="rightBoxItem" onClick={() => {
                        reset();

                        if (props.cop) {
                            window.location.href = window.location.origin;
                        } else {
                            props.setDriver(null);
                        }
                    }}>CHANGE DRIVER</div>
                    <div id="muteMusic" className="rightBoxItem" onClick={() => {
                        if (props.track.music.muted) {
                            props.track.music.muted = false;
                        } else {
                            props.track.music.muted = true;
                        }
                    }}>{(props.track.music.muted && 'TURN MUSIC ON') || 'TURN MUSIC OFF'}</div>
                    <div className="rightBoxItem" onClick={() => {
                        setCredits(true);
                    }}>CREDITS</div>
                </div>
            </div>
        </div>
    );
}

export default Race;
