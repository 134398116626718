import './Car.css';

const dropzoneStyle = (isMobile, props) => (
    isMobile? {
    bottom: String(5 + props.pos) + '%',  
    right: String(-20) + '%',  
    "--primaryC": props.driver.p_color || '#e74c3c',
    "--secondaryC": props.driver.s_color , 
    "--tyreC": props.tyre.color, 
    display: 'inline-block'
}: {
    left: String(props.pos) + '%',  
    top: String(10) + '%',  
    "--primaryC": props.driver.p_color || '#e74c3c',
    "--secondaryC": props.driver.s_color , 
    "--tyreC": props.tyre.color, 
    display: 'inline-block'
}
);

function Car({props}) {
    return (
        <div id="Container" className={`${props.isMobile ? "shrink" : ""}`} style={dropzoneStyle(props.isMobile, props)}>
            <div id="nose-top"></div>
            <div id="nose-bottom"></div>
            <div id="nose"></div>
            <div id="front-wing"></div>
            <div id="top-front-wing-trim"></div>
            <div id="bottom-front-wing-trim"></div>
            <div id="top-front-wing-trim-2"></div>
            <div id="bottom-front-wing-trim-2"></div>
            <div id="top-front-wing"></div>
            <div id="top-front-wing-tail"></div>
            <div id="bottom-front-wing"></div>
            <div id="bottom-front-wing-tail"></div>
            <div id="bottom-front-wheel" class="wheel"></div>
            <div id="bottom-back-wheel"></div>
            <div id="top-front-wheel" class="wheel"></div>
            <div id="top-back-wheel"></div>
            <div id="rear-body"></div>
            <div id="rear-wing-bg"></div>
            <div id="rear-wing"></div>
            <div id="top-body-curve"></div>
            <div id="top-body-curve-cut"></div>
            <div id="top-body-curve-straight"></div>
            <div id="top-body-curve-straight-2"></div>
            <div id="bottom-body-curve"></div>
            <div id="bottom-body-curve-cut"></div>
            <div id="bottom-body-curve-straight"></div>
            <div id="bottom-body-curve-straight-2"></div>
            <div id="back-body-curve"></div>
            <div id="body-hood"></div>
            <div id="back-body"></div>
            <div id="back-body-top"></div>
            <div id="back-body-bottom"></div>
            <div id="back-body-2"></div>
            <div id="top-spoke-1"></div>
            <div id="top-spoke-2"></div>
            <div id="top-spoke-3"></div>
            <div id="top-spoke-4"></div>
            <div id="bottom-spoke-1"></div>
            <div id="bottom-spoke-2"></div>
            <div id="bottom-spoke-3"></div>
            <div id="bottom-spoke-4"></div>

            <div id="back-spoke"></div>

            <div id="mirror-top"></div>
            <div id="mirror-bottom"></div>
            <div id="driver-bg"></div>
            <div id="driver-wheel"></div>
            <div id="driver-helmet"></div>
            <div id="bottom-body-spine"></div>
            <div id="top-body-spine"></div>
            <div id="end-body-spine"></div>
            <div id="top-body-spine-2"></div>
            <div id="bottom-body-spine-2"></div>
            <div id="label">{props.driver.name}{props.player && '\n(You)'}</div>
        </div>
    )
}



export default Car;