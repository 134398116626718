import mixpanel from 'mixpanel-browser';
mixpanel.init('2fd8dcc0b410c00065ac60b06cbd80f4', {debug: true});

let env_check = !(window.location.origin).includes('localhost');
console.log("ENV CHECK", env_check);

let actions = {
  identify: (id) => {
    if (env_check) mixpanel.identify(id);
  },
  alias: (id) => {
    if (env_check) mixpanel.alias(id);
  },
  track: (name, props) => {
    if (env_check) mixpanel.track(name, props);
  },
  people: {
    set: (props) => {
      if (env_check) mixpanel.people.set(props);
    },
  },
};

export let Mixpanel = actions;